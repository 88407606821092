import { Link } from "react-router-dom";
import './css/Navbar.css';

const Navbar = () => {
  return ( 
    <nav className="navbar">
      <h1><a href="/">The hrk Blog</a></h1>
      <div className="links">
        <a href="/login">Login</a>
        <a href="/create" className="newblog">New Blog</a>
      </div>
    </nav>
  );
}
 
export default Navbar;