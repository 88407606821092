import './css/Login.css';

const Login = () => {
  return (
    <html>
      <body>
        <div className="main">
          <div className="form-wrapper">
            <h1>Login</h1>
            <form method="POST" action="./login_check.php">
                  <div className="form-item">
                      <label for="email"></label>
                      <input required type="ID" pattern="[a-zA-Z0-9_-*]{3,10}" title="アルファベットと数字3~11文字で入力してください" name="login_name" placeholder="ID"></input>
                  </div>
                  <div className="form-item">
                      <label for="password"></label>
                      <input required type="password" pattern="[a-zA-Z0-9_-]{3,20}" title="アルファベットと数字3~20文字で入力してください" name="pwd" placeholder="Password"></input>
                  </div>
                  <div className="button-panel">
                      <input type="submit" class="button" title="Sign In" value="Login"></input>
                  </div>
              </form>
              <div className="form-footer">
                  <p><a href="/signup">Sign Up from here</a></p>
              </div>
          </div>
        </div>
      </body>
    </html>
  );
}
 
export default Login;