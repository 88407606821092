import './css/SignUp.css'

const SignUp = () => {

  const ssoClick = (kind) => {
    fetch("https://api.tanigawahrk.com/login", {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "x-api-key": "d9xkJtVjUB4rx7XU4GKOWFKD0eIr1341enQXItB0",
        "kind": kind
      }
    });
  }

  return (
    <html>
      <body>
        <div class="main">
          <div class="form-wrapper">
            <h1>Signup</h1>
            <div class="method">
              <form method="POST" action="./register_check.php">
                  <div class="form-item">
                      <label for="email"></label>
                      <input required type="ID" pattern="[a-zA-Z0-9_-]{3,10}" title="アルファベットと数字3~10文字で入力してください" name="login_name" placeholder="ID"></input>
                  </div>
                  <div class="form-item">
                      <label for="password"></label>
                      <input required type="password" pattern="[a-zA-Z0-9_-]{3,20}" title="アルファベットと数字3~20文字で入力してください" name="pwd" placeholder="Password"></input>
                  </div>
                  <div class="button-panel">
                      <input type="submit" class="button" title="Sign Up" value="Signup"></input>
                  </div>
              </form>
              <div className='ssomethod'>
                <a href="https://api.tanigawahrk.com/login" className='ssobutton'><img src="./img/btn_login_base.png"></img></a>
              </div>
            </div>
          </div>
          </div>
      </body>
    </html>
  );
}
 
export default SignUp;